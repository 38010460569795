module.exports = {

	title: 'HeySens报警管理后台',

	/**
	 * @type {boolean} true | false
	 * @description Whether fix the header
	 */
	fixedHeader: false,

	/**
	 * @type {boolean} true | false
	 * @description Whether show the logo in sidebar
	 */
	sidebarLogo: false
}
