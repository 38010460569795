import axios from 'axios'
import store from '@/store'
import {
	getToken
} from '@/utils/auth'

// create an axios instance

let baseURL = 'https://www.heysens.cloud:8443' 

const service = axios.create({
	//baseURL: 'http://xl.zjk.site', //process.env.VUE_APP_BASE_API, // url = base url + request url
	//baseURL: 'http://manageservices.longoor.com', //process.env.VUE_APP_BASE_API, // url = base url + request url
	//baseURL: 'http://175.27.161.215:8000', //process.env.VUE_APP_BASE_API, // url = base url + request url
	//baseURL: 'http://192.168.0.120:8000', //process.env.VUE_APP_BASE_API, // url = base url + request url
	//baseURL: 'http://www.heysens.cloud:8000', //process.env.VUE_APP_BASE_API, // url = base url + request url
	baseURL: baseURL, //process.env.VUE_APP_BASE_API, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent

		if (store.getters.token) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			config.headers['admintoken'] = getToken()
		}
		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// if the custom code is not 20000, it is judged as an error.
		if (res.code !== 200 && res.code !== 0) {
			// Message({
			//   message: res.message || 'Error',
			//   type: 'error',
			//   duration: 5 * 1000
			// })


			// 判断是否过期
			// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
			if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code == 3) {
				// to re-login				
				// MessageBox.confirm('您已注销，可以取消以停留在此页面，或重新登录', '确认注销', {
				// 	confirmButtonText: '重新登录',
				// 	cancelButtonText: '取消',
				// 	type: 'warning'
				// }).then(() => {
				// 	store.dispatch('user/resetToken').then(() => {
				// 		location.reload()
				// 	})
				// })

				//这里想办法用ConfirmDialog搞定

			}
			//可以在家一层判断，判断是否有返回值data，，和上面判断是否过期一样

			// return Promise.reject(new Error(res.message || 'Error'))
		} else {
			// 判断通过，返回res
			return res
		}
	},
	error => {
		console.log('err' + error) // for debug
		// Message({
		// 	message: error.message,
		// 	type: 'error',
		// 	duration: 5 * 1000
		// })

		//这里想办法用ConfirmDialog搞定

		return Promise.reject(error)
	}
)

export default service