<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <!-- <router-view @updateEventMenu="updateEventMenuHandler" /> -->
                <router-view />
            </div>
            <AppFooter />
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>

        <Toast />
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [
                {
                    label: '数据分析',
                    items: [{
                        label: '总计', icon: 'pi pi-fw pi-home', to: '/'
                    },
                    {
                        label: '图表统计', icon: 'pi pi-fw pi-chart-pie', to: '/charttable'
                    },
                        // {
                        //     label: '报告下载', icon: 'pi pi-fw pi-home', to: '/reportdownload'
                        // }
                    ]
                },
                {
                    label: '企业管理', icon: 'pi pi-fw pi-sitemap',
                    items: [
                        { label: '企业配置', icon: 'pi pi-fw pi-server', to: '/groupconfig' },
                        // {label: '部门配置', icon: 'pi pi-fw pi-sitemap', to: '/departmentmanage'},
                        // {label: '员工管理', icon: 'pi pi-fw pi-users', to: '/employeeconfig'}
                        { label: '发布通知', icon: 'pi pi-fw pi-bell', to: '/notifymanage' }
                    ]
                },
                {
                    label: '事件管理',
                    items: [
                        { label: '事件记录', icon: 'pi pi-fw pi-eye', to: '/eventrecords', badge: "0" },
                        { label: '传感设备管理', icon: 'pi pi-fw pi-cog', to: '/sensormanage' },
                        // {label: '事件策略', icon: 'pi pi-fw pi-pencil', to: '/eventstrategy'},
                        // {label: '目标注册', icon: 'pi pi-fw pi-eject', to: '/targetregist'}
                    ]
                },
                {
                    label: '后台管理',
                    items: [
                        // {label: '角色管理', icon: 'pi pi-fw pi-id-card', to: '/rolemanage'},
                        { label: '安全员管理', icon: 'pi pi-fw pi-sun', to: '/safermanage' },
                        { label: '日志管理', icon: 'pi pi-fw pi-book', to: '/logs' },
                    ]
                },
                {
                    label: '帮助',
                    items: [
                        { label: '文档', icon: 'pi pi-fw pi-question', to: '/document' },
                        // {label: '远程协助', icon: 'pi pi-fw pi-comments', to: '/remotehelp'}
                    ]
                }
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    mounted() {
        this.changeMenu();
        this.getTodayTotalEvent();
        this.timer = setInterval(this.getTodayTotalEvent, 10000);
    },
    methods: {

        changeMenu() {
            let usr_info = JSON.parse(window.localStorage.getItem('userinfo'));

            if (usr_info.admin == 0) {
                this.menu[3] = this.menu[4];
                this.menu.pop()
            } else if (usr_info.admin != 2) {
                this.menu[3].items.pop();
            }
        },

        getTodayTotalEvent() {
            var today = new Date();
            console.log("getTodayTotalEvent")
            this.$http('/api/admin/event/types', {
                enterprise_id: this.enterpriseId,
                time: today.getTime() - 86399999,
                times: today.getTime(),
            }).then(res => {
                if (res.code == 200) {
                    this.menu[2].items[0].badge = '' + (res.data.levels.checked + res.data.levels.unchecked);
                    this.$emit('todayTotalEvent', this.menu[2].items[0].badge);
                }
            })
        },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        // updateEventMenuHandler(e) {
        //     //window.localStorage.setItem('newEventCount', e)
        //     //this.menu[2].items[0].badge = e;
        // }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
